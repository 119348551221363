import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    constructor(
    ) {
    }

    setItem(itemName: string, value: any): void {
        localStorage.setItem(environment.appID + '_' + itemName, JSON.stringify(value));
    }

    getItem(itemName: string): any {
        if (localStorage.getItem(environment.appID + '_' + itemName)) {
            return JSON.parse(localStorage.getItem(environment.appID + '_' + itemName));
        } else {
            return null;
        }
    }

    removeItem(itemName: string): void {
        localStorage.removeItem(environment.appID + '_' + itemName);
    }
}
