import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {AccessService} from './access.service';
import {Router} from "@angular/router";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    constructor(
        private http: HttpClient,
        private toastrService: ToastrService,
        private accessService: AccessService,
        private localStorageService: LocalStorageService,
        private router: Router,
    ) {
    }

    request(action: string, dataObject: object = null, url = 'api/api.php'): Observable<any> {

        return this.http
            .post(url, {
                action,
                data: dataObject,
            }, {
                headers: {"Authorization": this.accessService.token}
            })
            .pipe(
                tap((response: any) => {
                    if (response?.errors) {
                        response.errors.forEach((error: any) => {
                            this.toastrService.error(error, 'Fehler');
                        });
                    }

                    if (response?.invalid_token) {
                        this.localStorageService.removeItem('token');
                        this.accessService.token = '';
                        this.accessService.user = null;
                        this.router.navigate(['/login']);
                    }
                })
            );
    }
}




