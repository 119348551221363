import {Injectable} from '@angular/core';
import {UserType} from "../page/interfaces";

interface Company {
    id: string;
    name: string;
}

interface User {
    id: string;
    type: string;
    name: string;
    email: string;
}

@Injectable({
    providedIn: 'root'
})
export class AccessService {

    private _token: string = '';
    private _user: User = null;

    constructor() {
    }

    get token(): string {
        return this._token;
    }

    set token(token: string) {
        this._token = token;
    }

    get companies() {
        return this.user.companies as Company[];
    }

    get user() {
        return this._user;
    }

    set user(user: any) {
        this._user = user;
    }

    isAdmin(): boolean {
        return this._user?.type.includes(UserType.ADMIN);
    }

    isEditor(): boolean {
        return this._user?.type.includes(UserType.EDITOR);
    }

    isWarehouse(): boolean {
        return this._user?.type.includes(UserType.WAREHOUSE);
    }

    hasCompany(ids: string[]): boolean {
        return this.companies.some(company => ids.includes(company.id));
    }

    hasMultipleCompanies(): boolean {
        return this.companies.length > 1;
    }
}
