export enum ViewMode {
    VIEW,
    EDIT,
    INSERT,
}

export enum Status {
    /** Neu */
    NEW = 'new',
    /** Offen */
    SCHEDULED = 'scheduled',
    /** In Arbeit */
    ARRIVED = 'arrived',
    /** Maßnahme umgesetzt */
    COMPLETED = 'completed',
}

export enum UserType {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    WAREHOUSE = 'WAREHOUSE',
}
